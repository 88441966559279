import { StaticQuery, graphql } from 'gatsby';
import React, { ChangeEventHandler } from 'react';
import { Select } from 'src/components/forms/Select';
import { Query } from 'src/graphql-types';

export const query = graphql`
  query FormProductsQuery {
    allSanityFormProducts(
      sort: { order: ASC, fields: featuredProducts___name }
    ) {
      edges {
        node {
          featuredProducts {
            name
            id
          }
        }
      }
    }
  }
`;

interface ProductSelectProps {
  onChange: ChangeEventHandler<HTMLSelectElement>;
}

export function ProductSelect({ onChange }: ProductSelectProps) {
  return (
    <Select
      id="box_type"
      name="box_type"
      autoComplete="number"
      placeholder="Pick a Box"
      onChange={onChange}
      defaultValue=""
    >
      <option value="" disabled hidden>
        Pick your box
      </option>

      <StaticQuery
        query={query}
        render={(data: Query) => {
          const products =
            data.allSanityFormProducts.edges[0].node.featuredProducts;

          return products.map(({ id, name }) => {
            return <option key={id}>{name}</option>;
          });
        }}
      />
    </Select>
  );
}
