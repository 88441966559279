import cx from 'classnames';
import React, { LabelHTMLAttributes, ReactNode } from 'react';

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  children: ReactNode;
  className?: string;
}

export function Label({ children, className, ...theRest }: LabelProps) {
  return (
    <label
      {...theRest}
      className={cx(className, 'block text-sm font-medium text-gray-700')}
    >
      {children}
    </label>
  );
}
