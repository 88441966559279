import { StaticQuery, graphql } from 'gatsby';
import React, { ChangeEventHandler } from 'react';
import { Select } from 'src/components/forms/Select';
import { Query } from 'src/graphql-types';

export const query = graphql`
  query FormSizesQuery {
    allSanityFormProducts(
      sort: { order: ASC, fields: featuredProducts___name }
    ) {
      edges {
        node {
          featuredProducts {
            name
            options {
              _key
              name
            }
          }
        }
      }
    }
  }
`;

interface SizeSelectProps {
  boxType: string;
  onChange: ChangeEventHandler<HTMLSelectElement>;
}

export function SizeSelect({ onChange, boxType }: SizeSelectProps) {
  return (
    <Select
      id="size"
      name="size"
      autoComplete="number"
      onChange={onChange}
      defaultValue=""
    >
      <StaticQuery
        query={query}
        render={(data: Query) => {
          const products =
            data.allSanityFormProducts.edges[0].node.featuredProducts;

          const product = products.find(({ name }) => name === boxType);

          if (product == null) {
            return null;
          }

          return product.options.map(({ name, _key }) => {
            return <option key={_key}>{name}</option>;
          });
        }}
      />
    </Select>
  );
}
