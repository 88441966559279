import React from 'react';
import { InputHTMLAttributes } from 'react';

export function Checkbox(props: InputHTMLAttributes<HTMLInputElement>) {
  return (
    <div className="flex items-start">
      <div className="flex items-center h-5">
        <input
          {...props}
          id={props.name}
          type="checkbox"
          className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={props.name} className="font-medium text-gray-700">
          {props.name}
        </label>
      </div>
    </div>
  );
}
