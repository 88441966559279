import { Link } from 'gatsby';
import React, { ChangeEvent, useState } from 'react';

import { Checkbox } from './Checkbox';
import { Input } from './Input';
import { Label } from './Label';
import { ProductSelect } from './ProductSelect';
import { Radio } from './Radio';
import { Select } from './Select';
import { SizeSelect } from './SizeSelect';

const NORMAL_BOXES = [
  'Original Savoury Gray',
  'The Lux Savoury Gray',
  'Sweet & Savoury Mix',
  'Sweet Mae',
  'The Christmas Box',
];
const MINI_MIN_QTY = 2;
const SNACK_MIN_QTY = 4;
const ALLERGIES = ['Vegetarian', 'Vegan', 'Dairy Free', 'Gluten Free'];

function checkFeatureBox(boxType: string) {
  return !NORMAL_BOXES.includes(boxType);
}

export const ContactForm = () => {
  const [minQuantity, setMinQuantity] = useState(1);
  const [showCheese, setShowCheese] = useState(false);
  const [boxType, setBoxType] = useState(NORMAL_BOXES[0]);
  const [size, setSize] = useState('Mini');
  const [isDelivery, setIsDelivery] = useState(false);

  function isSizeForCheese(newSize = size) {
    return ['Small', 'Medium'].includes(newSize);
  }

  const onSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const size = event.target.value;

    setSize(size);

    switch (size) {
      case 'Snack':
        setMinQuantity(SNACK_MIN_QTY);
        break;
      case 'Mini':
        setMinQuantity(MINI_MIN_QTY);
        break;
      default:
        setMinQuantity(1);
        break;
    }

    setShowCheese(boxType === NORMAL_BOXES[0] && isSizeForCheese(size));
  };

  const onBoxChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const box = event.target.value;

    setBoxType(event.target.value);

    if (checkFeatureBox(event.target.value)) {
      setMinQuantity(1);
    }

    if (box !== NORMAL_BOXES[0]) {
      setShowCheese(false);
    } else if (box === NORMAL_BOXES[0] && isSizeForCheese()) {
      setShowCheese(true);
    }
  };

  return (
    <form action="https://formspree.io/f/xnqlopvr" method="POST">
      <div className="overflow-hidden shadow sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="col-span-6">
            <p className="p-2 mb-6 text-sm text-center bg-blue-200 rounded">
              If you're looking for a bulk order, visit{' '}
              <Link to="/corporate-events">Corporate & Events</Link>
            </p>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Label htmlFor="first-name">First name</Label>
              <Input
                type="text"
                name="first-name"
                autoComplete="given-name"
                required
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Label htmlFor="last-name">Last name</Label>
              <Input
                type="text"
                name="last-name"
                autoComplete="family-name"
                required
              />
            </div>

            <div className="col-span-6 sm:col-span-4">
              <Label htmlFor="_replyto">Email address</Label>
              <Input
                type="text"
                name="_replyto"
                autoComplete="email"
                required
              />
            </div>

            <div className="col-span-6 sm:col-span-2">
              <Label htmlFor="phone">Phone number</Label>
              <Input type="tel" name="phone" autoComplete="phone" required />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Label htmlFor="date">Date</Label>
              <Input type="date" name="date" autoComplete="date" />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Label htmlFor="box_type">Box Type</Label>
              <ProductSelect onChange={onBoxChange} />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Label htmlFor="size">Size</Label>
              <SizeSelect onChange={onSizeChange} boxType={boxType} />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Label htmlFor="quantity">Quantity</Label>
              <Input
                type="number"
                name="quantity"
                min={minQuantity}
                placeholder={`${minQuantity}`}
              />
            </div>

            {showCheese && (
              <div className="col-span-6">
                <Label htmlFor="cheese">Cheeses</Label>
                <Select id="cheese" name="cheese">
                  <option>Brie with Honeycomb</option>
                  <option>Herb and Garlic Boursin</option>
                </Select>
              </div>
            )}

            <div className="col-span-6">
              <Label htmlFor="allergies">
                Dietary restrictions or allergies?
              </Label>
              <div className="grid grid-cols-4 my-4">
                {ALLERGIES.map((item, index) => (
                  <Checkbox key={`${item}-${index}`} name={item} />
                ))}
              </div>
              <div className="mt-1">
                <textarea
                  id="allergies"
                  name="allergies"
                  rows={3}
                  className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Any other allergies or dietary restrictions?"
                ></textarea>
              </div>
            </div>
            {isDelivery && (
              <div className="col-span-6">
                <Label htmlFor="address">Address</Label>
                <div className="mt-1">
                  <Input type="text" name="address" required />
                </div>
              </div>
            )}
            <div className="col-span-6 space-y-2">
              <Label htmlFor="delivery" className="flex items-center">
                <Radio
                  name="delivery"
                  value="delivery"
                  onChange={() => setIsDelivery(true)}
                />
                Delivery (fee based on location)
              </Label>

              <Label htmlFor="delivery" className="flex items-center">
                <Radio
                  name="delivery"
                  value="pickup"
                  onChange={() => setIsDelivery(false)}
                />
                Pickup (Headingley)
              </Label>
            </div>
            <div className="col-span-6">
              <Label htmlFor="about">
                Any other info you'd like us to know?
              </Label>
              <div className="mt-1">
                <textarea
                  id="about"
                  name="about"
                  rows={3}
                  className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 py-3 text-right bg-gray-50 sm:px-6">
          <button
            type="submit"
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Send
          </button>
        </div>
      </div>
    </form>
  );
};
