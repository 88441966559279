import '@fontsource/sue-ellen-francisco';
import BlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import * as React from 'react';
import { Query } from 'src/graphql-types';

import { ContactForm } from '../components/forms/ContactForm';
import Layout from '../layout';
import { serializers } from '../serializers';

export const query = graphql`
  query ContactPageQuery {
    pickup: sanityPickupDelivery(slug: { current: { eq: "pickup-delivery" } }) {
      id
      title
      _rawBlurb
    }
    hours: sanityPickupDelivery(slug: { current: { eq: "hours-orders" } }) {
      id
      title
      _rawBlurb
    }
  }
`;

const ContactPage = ({
  data,
}: {
  data: {
    pickup: Query['sanityPickupDelivery'];
    hours: Query['sanityPickupDelivery'];
  };
}) => {
  return (
    <Layout title="Order Form/Contact us">
      <div
        className="grid w-full grid-cols-1 p-5 bg-white md:p-10 md:gap-10 md:grid-cols-2"
        style={{ maxWidth: 1200 }}
      >
        <div style={{ marginTop: '-3rem' }}>
          <h2>{data.pickup.title}</h2>
          <BlockContent
            blocks={data.pickup._rawBlurb}
            serializers={serializers}
          />
          <h2>{data.hours.title}</h2>
          <BlockContent
            blocks={data.hours._rawBlurb}
            serializers={serializers}
          />
        </div>
        <ContactForm />
      </div>
    </Layout>
  );
};

export default ContactPage;
